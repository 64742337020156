import ReportService from "../service/reportService";

export const userDetail = (data) => {
    return {
        type: 'ADD_USER_INFO',
        payload: data
    }
}
export const logout = (data) => {
    return {
        type: 'LOGOUT',
        payload: data
    }
}

export const userReportees = (postData) => {
    return async (dispatch) => {
		try {
			const response = await ReportService.getUserReportees(postData);
            if (response?.status === 200 && response?.data?.status === 200) {
				let { data } = response.data;
				if (data && data) {
					let is_secondary = (postData?.is_secondary) ? true:false;
					console.log("is_secondary",is_secondary,data.data)
					dispatch(success(data.data,is_secondary));
				}
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload,is_secondary=false) {
		let key2add = (is_secondary) ? 'ADD_USER_REPORTEES_SECONDARY':'ADD_USER_REPORTEES';
		return { type: key2add, payload };
	}
	
}

export const userManager = (postData) => {
    return async (dispatch) => {
		try {
			const response = await ReportService.getUserManager(postData);
            if (response.status === 200 && response.data.status === 200) {
				let { data } = response.data;
				if (data && data.data) {
                    //secureStorage.setItem("user_manager", data.data);
					dispatch(success(data.data));
				}
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "ADD_USER_MANAGER", payload };
	}
	
}

export const setUserManager = (payload) => {
    return { type: "ADD_USER_MANAGER", payload };
	
}

export const setUserReporees = (payload) => {
    return { type: "ADD_USER_REPORTEES", payload };
	
}

export const getUserByRole = (postData) => {
    return async (dispatch) => {
		try {
			const response = await ReportService.getUserByRole(postData);
            if (response.status === 200 && response.data.status === 200) {
				let { data } = response.data;
				if (data && data.data && data.data.user_list) {
                    dispatch(success(data.data.user_list));
				}
			} else dispatch(success({}));
		} catch (err) {
			dispatch(success({}));
		}
	};
    function success(payload) {
		return { type: "GET_RBH_LIST", payload };
	}
	
}




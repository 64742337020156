import secureStorage from "../../config/encrypt";
let authUser = "";
let initialState = "";
try {
	authUser = secureStorage.getItem("authUserInfo");
	console.log('authUser========',authUser)
	initialState =
		authUser ? { loggedIn: true, user_data: authUser } : {};
} catch (err) {
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ADD_USER_INFO":
			return {
				...state,
				loggedIn:true,
				user_data: action.payload,
			};

		case "USER_LOGIN_STATUS":
			return {
				...state,
				loggedIn:true,
				loginStatus: action.payload,
			};
		case "LOGOUT":
			return {
				loggedIn: false,
				user_data:{},
				loginStatus:{}
			};
		case "ADD_USER_REPORTEES":
			return {
				...state,
				loggedIn:true,
				user_reportees: action.payload,
			};
		case "ADD_USER_MANAGER":
			return {
				...state,
				loggedIn:true,
				user_manager: action.payload,
			};
		case "GET_RBH_LIST":
			return {
				...state,
				loggedIn:true,
				rbh_list: action.payload,
			};
		case "USERS_LOGIN_SUCCESS":
			return {
				captchaImage: (action.captcha && action.captcha.image) || "",
				captchaValue: (action.captcha && action.captcha.value) || "",
				captchaError: (action.captcha && action.captcha.error) || "",
				is_radis: (action.captcha && action.captcha.is_radis) || "",
			};
		case "ADD_USER_REPORTEES_SECONDARY":
				return {
					...state,
					loggedIn:true,
					user_reportees_secondary: action.payload,
				};
		default:
			return state;
	}
};

export default userReducer;

import "./App.scss";
import secureStorage from "./config/encrypt";
import Routes from "./routes/routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "./store/allAction";
import store from "./store/store";
import { ReportService } from "./service";
import { IDEAL_DURATION } from "./config/constant";
function App() {
	return (
		<div className="App">
			<ToastContainer position={toast.POSITION.TOP_RIGHT} />
			<Routes />
		</div>
	);
}

document.addEventListener('click', (e) => {
	ideadetection();
});

document.addEventListener('keydown', (event) => {

	ideadetection();

}, false);

const ideadetection = () => {
	if (secureStorage.getItem('authUserInfo')) {
		var startDate = new Date(secureStorage.getItem('authDateTime'));
		var endDate = new Date();
		var seconds = (endDate - startDate) / 1000;

		if (seconds > IDEAL_DURATION) {
			let userdata=secureStorage.getItem("authUserInfo");
			secureStorage.removeItem("authUserInfo");
			secureStorage.removeItem("user_manager");
			secureStorage.removeItem("user_reportees");
			secureStorage.removeItem("user_reportees_secondary");
			secureStorage.removeItem("authDateTime");
			store.dispatch(logout());
			ReportService.userLogOut({user_id:userdata.userId,type:'ideal'}); 
			toast.warning(`LogOut due to ideal condition`);
			window.location.href = '/login'
		}
		else {
			secureStorage.setItem("authDateTime", new Date());
		}
	}
}
export default App;

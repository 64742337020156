export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
};

export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_MDY = "mmm dd, yyyy";

export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;


export const displayAmount = (amount) => {
	let amount2display= `IDR ${amount}`;
	return amount2display;
};



// manage menu Permission for Roles 
// export const hasAccessPermission = (modulename,page='view') => {
// 	const userAccess = secureStorage.getItem("userAccess");
// 	let Permission = userAccess&& userAccess[modulename] && userAccess[modulename][page]=="1"?true:false;
// 	return Permission;
// };
export const maxDATE = (from_date,d=0)=>{
	var date = new Date(from_date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + d);
	return lastDay
}

export const DATE_FORMAT_MY = 'MM/yyyy';

export const AMOUNT_FORMAT_TYPE = [
	{ value: 'en', label: 'Indian Accounting Standard System' },
	{ value: 'id', label: 'Indonesian Accounting Standard System' }
]

// Radis
export const IS_REDIS = true;


export const LEAD_DETAILS_LEVEL = [
	{ value: 'loan_id', label: 'Loan_ID' },
	{ value: 'application_submitted', label: 'APPLICATION_SUBMISSION' },
	{ value: 'customer_name', label: 'Customer_Name' },
	{ value: 'financier', label: 'Financier' },
	{ value: 'financier_branch', label: 'FINANCIER_BRANCH'}, 
	{ value: 'rbh', label: 'RBH' },
	{ value: 'ZM', label: 'ZM' },
	{ value: 'AM', label: 'AM' },
	{ value: 'bm_id', label: 'BM_ID' },
	{ value: 'BM', label: 'BM' },
	{ value: 'ARO', label: 'ARO' },
	{ value: 'bro_agent_id', label: 'BRO_AGENT_SFAID' },
	{ value: 'bro_agent', label: 'BRO_AGENT' },
	{ value: 'sales_type', label: 'SALES_TYPE' },
	{ value: 'amount_disbursal', label: 'amount_disbursal' },
	{ value: 'tenure', label: 'tenure' },
	{ value: 'product_offering', label: 'product_offering' },
	{ value: 'status_name', label: 'LOAN_STATUS' },
	{ value: 'sub_status_name', label: 'LOAN_SUB_STATUS' },
	{ value: 'application_disbursed_timestamp', label: 'APPLICATION_DISBURSED_TIMESTAMP' },
	{ value: 'dealer_name', label: 'DEALER_NAME'}, 
	{ value: 'dealer_id', label: 'DEALER_ID'},
	{ value: 'is_cross_application', label: 'CROSS_APPLICATION'}
];

export const LEAD_DETAILS_LEVEL_FN = [
	{ value: 'loan_id', label: 'Loan_ID' },
	{ value: 'application_submitted', label: 'APPLICATION_SUBMISSION' },
	{ value: 'po_number', label: 'PO_NUMBER' },
	{ value: 'po_received_timestamp', label: 'PO_RECEIVED_TIMESTAMP' },
	{ value: 'customer_name', label: 'Customer_Name' },
	{ value: 'financier', label: 'Financier' },
	{ value: 'financier_branch', label: 'FINANCIER_BRANCH'}, 
	{ value: 'rbh', label: 'RBH' },
	{ value: 'ZM', label: 'ZM' },
	{ value: 'AM', label: 'AM' },
	{ value: 'bm_id', label: 'BM_ID' },
	{ value: 'BM', label: 'BM' },
	{ value: 'ARO', label: 'ARO' },
	{ value: 'bro_agent_id', label: 'BRO_AGENT_SFAID' },
	{ value: 'bro_agent', label: 'BRO_AGENT' },
	{ value: 'sales_type', label: 'SALES_TYPE' },
	{ value: 'status_name', label: 'LOAN_STATUS' },
	{ value: 'sub_status_name', label: 'LOAN_SUB_STATUS' },
	{ value: 'application_disbursed_timestamp', label: 'APPLICATION_DISBURSED_TIMESTAMP' },
	{ value: 'dealer_name', label: 'DEALER_NAME'}, 
	{ value: 'dealer_id', label: 'DEALER_ID'},
	{ value: 'is_cross_application', label: 'CROSS_APPLICATION'}

];
export const IDEAL_DURATION=3600;

import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import secureStorage from "../config/encrypt";

const Header = lazy(() => import("../view/common/Header"));
const Footer = lazy(() => import("../view/common/Footer"));
const Login = lazy(() => import("../view/user/Login"));
const LoginOtp = lazy(() => import("../view/user/LoginOtp"));
const PageNotFound = lazy(() => import("../view/notfound/PageNotFound"));

// Routes for Authorized access Only
const PerformanceReport = lazy(() => import("../view/performance/PerformanceReport"));
const PendingPaymentReport = lazy(() => import("../view/pending-payment/PaymentReport"));
const LoanDetailView = lazy(() => import("../view/pending-payment/LoanDetailView"));

class CustomRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ht: 500,
			user_data: {}
		};
	}

	static getDerivedStateFromProps = (nextProps, state) => {
		const updatedProps = {};
		if (
			//eslint-disable-next-line
			nextProps.user_data != state.user_data
		) {
			updatedProps["user_data"] = nextProps.user_data;
		}
		return updatedProps
	};

	componentDidMount = async () => {
		this.setState({ ht: window.innerHeight - 107 });
		window.addEventListener("resize", () => {
			this.setState({ ht: window.innerHeight - 107 });
		}, false);
	}

	render() {
		//const {user_data} = this.state;
		const loginResponse = secureStorage.getItem("authUserInfo");
		return (
			<Router>
				<Suspense fallback={<div className="loader is-active">Loading...</div>}>
					{loginResponse && Object.keys(loginResponse).length ? <Header /> : null}
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/logout" element={<LoginOtp />}/>
						<Route path="/report/:report_type" element={<PerformanceReport/>} />
						<Route path="/pending-payment-report" element={<PendingPaymentReport/>} />
						<Route path="/pending-payment-report/details/:query" element={<LoanDetailView/>} />
						<Route path='*' element={<PageNotFound />} />
					</Routes>
					{loginResponse && Object.keys(loginResponse).length ? <Footer /> : null }
				</Suspense>
			</Router>
		);
	}
}

const mapStateToProps = ({ user }) => {
	return {
		loggedIn: user.loggedIn,
		user_data:user.user_data
	};
};
export default connect(mapStateToProps, null)(CustomRouter);

//export default Routes;

import MasterService from "./MasterService";

const serviceList = {
	...MasterService,
	// THIS IS SAMPLE SERVICE FUNCTION
	userLogin(body) {
		return this.post("user/login", body);
	},
	userOtpSend(body) {
		return this.post("user/send-otp", body);
	},
	otpLogin(body) {
		return this.post("user/otp_login", body);
	},
	userLogOut(body) {
		return this.post("user/logout", body);
	},
	getFinancierList() {
		return this.get("/common/financier_list");
	},
	getPerformanceReport(body,report_type='performance') {
		if(report_type === 'performance') 
			return this.post("/lead/get_leads",body);
		else return this.post("/lead/get_productivity_report",body);
	},
	getUserReportees(body) {
		return this.post("/common/get_reportees",body);
	},
	getUserManager(body) {
		return this.post("/common/get_all_manager",body);
	},
	getReportsDataList(body) {
		return this.post("/lead/get_reports",body);
	},
	getUserByRole(body) {
		return this.post("/common/get_users_by_role",body);
	},
	getPaymentPendingReport(body) {
			return this.post("/lead/get_pending_payment_report",body);
	},
	getPaymentPendingDetilReport(body) {
		return this.post("/lead/get_pending_detail_report",body);
	},
};

export default serviceList;

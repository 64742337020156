import axios from "axios";
import { API_URL } from "./../config/constant";
import secureStorage from "./../config/encrypt";
import { toast } from "react-toastify";
import store from '../store/store';
import { logout } from "../store/allAction";
import serviceList from "./reportService";

axios.interceptors.response.use(
	(response) => {
		if (response.data && response.data.data && !response.data.data.isNotification) {
			// let userId = (secureStorage.getItem("authUserInfo") && secureStorage.getItem("authUserInfo")['user_data'] ) ? secureStorage.getItem("authUserInfo")['user_data']['user_id'] : 0; 
			// if(userId > 0){
			// 	store.dispatch(notificationUpdate(userId));
			// } 
		}
		//eslint-disable-next-line
		if (response.data && response.data.status != 200) {
			// console.error(`${response.data.status} - ${response.data.message}`, response.data)
			// if(response.data.status == 400)
			//     window.commonErrorToast(response.data.message)
			//     toast.error(`${response.data.status} - ${response.data.message}`);
		}
		return response;
	},
	(error) => {
		// handle error
		if (error.response) {
			if (error.response.status === 503) {
				// toast.error(`${error.response.status} - ${error.response.statusText}`);
			} else if (error.response.status === 401) {
				secureStorage.removeItem("authUserInfo")
				if (error.response.data.errors === "TokenEmptyInCache")
					toast.error(
						`${error.response.data.status} - ${error.response.data.message}`
					);

				//window.location.href = '/refresh-token?redirectUrl='+window.location.pathname;
				//window.location.href = "/login";
			} else if (
				error.response.data.status === 400 &&
				(error.response.data.message === "invalid_refresh_token" ||
					error.response.data.message === "access_token_expired")
			) {
				// if(error.response.data.errors == "TokenEmptyInCache")
				//toast.error(`${error.response.data.status} - ${error.response.data.message}`);
				// window.location.href = '/logout';
			} else if (error.response.data.status === 400) {
				toast.error(error.response.data.message);
			}
		}

		return error.response;
	}
);

window.commonErrorToast = function (error) {
	if (typeof error === "string") toast.error(error);
	else if (error.message) toast.error(error.message);
};

const ServiceMethod = {
	getToken() {
		// return secureStorage.getItem("authUserInfo") &&
		// 	secureStorage.getItem("authUserInfo")["token"]
		// 	? secureStorage.getItem("authUserInfo")["token"]
		// 	: "";
		return "d77bc90f-3350-47da-94c2-2930de73report"
	},
	jwtToken() {
		let token = secureStorage.getItem("authUserToken");
		return token;
	},
	delete(URL, body, headers) {
		this.authApi(URL);
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		headers["apikey"] = this.getToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.delete(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, {
			headers,
		});
	},
	post(URL, body, headers) {
		this.authApi(URL);
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["apikey"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.post(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, {
			headers,
		});
	},
	put(URL, body, headers) {
		this.authApi(URL);
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["apikey"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.put(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, {
			headers,
		});
	},
	get(URL, query, headers) {
		this.authApi(URL);
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["apikey"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.get(`${API_URL.COMMON_GATEWAY_API}${URL}`, {
			params: query,
			headers,
		});
	},
	patch(URL, body, headers) {
		this.authApi(URL);
		headers = headers || {};
		headers["Authorization"] = this.getToken();
		headers["apikey"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		headers["Accept-Language"] = secureStorage.getItem("lang");
		URL = URL[0] === "/" ? URL.substr(1) : URL;
		return axios.patch(`${API_URL.COMMON_GATEWAY_API}${URL}`, body, {
			headers,
		});
	},
	async authApi(url) {

		if(url.includes('login') || url.includes('logout') || url.includes('getCaptchaImage'  || url.includes('get_all_manager') )) return;
		let userdata = await secureStorage.getItem("authUserInfo");
		let body = { user_id: userdata?.userId,url:url }
		let headers = {};
		headers["Authorization"] = this.getToken();
		headers["apikey"] = this.getToken();
		headers["jwt-token"] = this.jwtToken();
		return axios.post(`${API_URL.COMMON_GATEWAY_API}user/checkauth`, body, {
			headers,
		}).then((response) => {
			if (response?.data?.data?.info === 0) {
				serviceList.userLogOut({user_id:[userdata?.userId],type:'force'}); 
						secureStorage.removeItem("authUserInfo");
						secureStorage.removeItem("user_manager");
						secureStorage.removeItem("user_reportees");
						secureStorage.removeItem("user_reportees_secondary");
						secureStorage.removeItem("authDateTime");
						store.dispatch(logout());
						window.location.href = '/login'
			}
		});
	}
};

export default ServiceMethod;
